<template>
    <div class="add-new-station">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.stationUpdate')"
            :title="$t('messages.stationUpdate')"
            @submitPressed="submitPressed"
        />
        <resource-form v-if="station" :resource-object="station" :resource="$Stations" base-url="/settings/stations"
                       create-message="messages.stationCreated" update-message="messages.stationUpdated"
                       :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceForm from '../components/ResourceForm.vue'

export default {
    components: {
        TitleBar,
        ResourceForm
    },
    data() {
        return {
            station: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Stations.getResource({id}).then((response) => {
                this.station = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
